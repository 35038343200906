<template>
  <b-modal v-model="modalUpdate" size="xl" no-fade id="update-expense" ref="update-expense" :no-close-on-backdrop="true" hide-footer hide-header hide-backdrop>
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">Editar Pago</h4>
              </div>
              <div class="col-2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <form class="forms-sample">
              <div class="form-group">
                <label for="date">Fecha</label>
                <input id="date" type="text" class="form-control disabled" disabled :value="payload.date">
                <!--                    <datetime v-model="payload.date" :config="dateConfig" id="date" class="form-control"></datetime>-->
                <div v-if="errors.hasOwnProperty('date')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.date[0]}}</small>
                </div>
              </div>
              <div class="form-group" v-if="!setInvoice">
                <label for="invoice_id">Invoice</label>
                <v-select
                  v-model="payload.invoice_id"
                  :options="invoices_dropdown"
                  id="invoice_id"
                  :reduce="r => r.code"
                >
                  <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                </v-select>
                <div v-if="errors.hasOwnProperty('invoice_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                  <small>{{errors.invoice_id[0]}}</small>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label for="amount">Monto</label>
                    <input v-model="payload.amount" id="amount" type="number" class="form-control">
                    <div v-if="errors.hasOwnProperty('amount')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                      <small>{{errors.amount[0]}}</small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label for="payment_method">Método de pago</label>
                    <select v-model="payload.payment_method" name="payment_method" id="payment_method" class="form-control">
                      <option value="1">Efectivo</option>
                      <option value="2">Cheque</option>
                      <option value="3">Tarjeta de Crédito</option>
                      <option value="4">Depósito / Transferencia</option>
                    </select>
                    <div v-if="errors.hasOwnProperty('payment_method')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                      <small>{{errors.payment_method[0]}}</small>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label for="currency">Moneda</label>
                    <input v-model="payload.currency" name="currency" id="currency" class="form-control" disabled>
                    <div v-if="errors.hasOwnProperty('currency')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                      <small>{{errors.currency[0]}}</small>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="reference">Referencia</label>
                    <input v-model="payload.reference" id="reference" type="text" class="form-control">
                    <div v-if="errors.hasOwnProperty('reference')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                      <small>{{errors.reference[0]}}</small>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label for="reference">Notas</label>
                    <textarea v-model="payload.notes" id="notes" class="form-control"></textarea>
                    <div v-if="errors.hasOwnProperty('notes')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                      <small>{{errors.notes[0]}}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-between mt-4">
                <div class="col-auto">
                  <button @click.prevent="deleteModel" class="btn btn-outline-dark btn-sm">Eliminar</button>
                </div>
<!--                <div class="col-auto">-->
<!--                  <button @click.prevent="updateModel" class="btn btn-gradient-info btn-md">Actualizar</button>-->
<!--                </div>-->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js'

export default {
  name: 'UpdateExpense',
  props: {
    payload: {
      type: Object,
      required: true
    },
    setInvoice: {
      type: Number,
      required: false
    },
    currentBalance: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      errors: {},
      selected: {},
      dateConfig: {
        altInput: true,
        enableTime: true,
        plugins: [new ConfirmDatePlugin({
          confirmIcon: '<i class="fa fa-check"></i>',
          confirmText: 'OK ',
          showAlways: false
        })],
        altFormat: 'F j, Y G:i K',
        dateFormat: 'Y-m-d H:i',
        time_24hr: false,
        locale: Spanish // locale for this instance only,
      },
      modalOptions: {
        id: 'modalLogout',
        msg: '¿Seguro que desea eliminar este registro?',
        title: 'Eliminando registro',
        okVariant: 'danger',
        okTitle: 'Sí, estoy seguro',
        cancelTitle: 'No, cancelar',
        dialogClass: 'tms-modal',
        noCloseOnBackdrop: true,
        hideBackdrop: true,
        centered: true
      }
    }
  },
  watch: {
    payload: {
      immediate: true,
      deep: true,
      handler (value) {
        const today = this.$moment()
        if (this.$moment(value.date).isBefore(today, 'd')) {
          this.errors.date = ['La fecha es inferior al día de hoy']
        } else {
          this.errors.date = ''
        }
      }
    }
  },
  methods: {
    ...mapMutations('Payment', ['TOGGLE_MODAL_UPDATE']),
    ...mapActions({
      update: 'Payment/update',
      create: 'Payment/create',
      delete: 'Payment/delete'
    }),
    updateModel () {
      /*
      this.update(this.payload)
        .then(() => {
          this.reset()
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          this.$swal(error.response.data.message, '', 'error')
        })
       */
    },
    deleteModel () {
      this.$bvModal.msgBoxConfirm(this.modalOptions.msg, this.modalOptions)
        .then(value => {
          if (value) {
            this.delete(this.payload)
              .then(() => {
                this.reset()
              })
              .catch((error) => {
                this.errors = error.response.data.errors
                this.$swal(error.response.data.message, '', 'error')
              })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    reset () {
      this.errors = {}
      this.TOGGLE_MODAL_UPDATE(false)
      this.$events.fire('refresh-table')
      this.$events.fire('refresh-invoice')
    }
  },
  computed: {
    ...mapGetters({
      modalUpdate: 'Payment/modalUpdate',
      invoices_dropdown: 'Invoice/invoices_dropdown'
    })
  },
  created () {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'update-payment') {
        this.payload.invoice_id = this.setInvoice
      }
    })
  }
}
</script>
